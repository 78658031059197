import React, { useContext } from 'react';
import { isDemo } from '../config/config';
import { IAPIClient } from './IAPIClient';
import { RestApiClient } from './RestApiClient';
import { StaticApiClient } from './StaticApiClient';

// Create a React Context provider for the app that exposes an empty object
// as the default value
type ApiClientContextType = { apiClient: IAPIClient };
export const ApiClientContext = React.createContext<ApiClientContextType>({ apiClient: undefined as IAPIClient });
export const useApiClientContext = () => useContext(ApiClientContext);

const apiClient: IAPIClient = isDemo() ? new StaticApiClient() : new RestApiClient();

// Create a React Context Provider that exposes the context and renders the children passed as prop to it
export const ApiClientProvider = ({ children }: { children?: React.ReactNode }) => {
    return (
        <ApiClientContext.Provider value={{ apiClient }}>
            {children}
        </ApiClientContext.Provider>
    )
}