import { useQuery } from 'react-query';
import { useApiClientContext } from '../../services/api/ApiClientProvider';
export type WorkoutLocation = {
    id: string;
    shortName: string;
    fullname: string;
    address: string;
    coordinates: Coordinates;
    activityTypesMetadata: ActivityTypeMetadata[];
};

export type ActivityTypeMetadata = {
    type: string;
};
export type Coordinates = {
    lat: string;
    long: string;
};


export const useLocations = () => {
    const { apiClient } = useApiClientContext();
    return useQuery({
        queryKey: ['locations'],
        retry: true,
        queryFn: () => apiClient.getLocations()
    });
};