
// Chakra imports
import { Box, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
// Assets
// Custom components
import ActivityCountCard from '../../../features/ActivityCount/ActivityCount';
import ActivityHeatmap from '../../../features/ActivityHeatmap/ActivityHeatmap';
import ActivityParticipationCount from '../../../features/ActivityParticipationCount/ActivityParticipationCount';
import ClassesTable from '../../../features/Classes/ClassesTable';
import FriendRequestsCount from '../../../features/FriendRequestsCount/FriendRequestsCount';
import UserAvailabilityHeatmap from '../../../features/UserAvailabilityHeatmap/UserAvailabilityHeatmap';
import UserCountCard from '../../../features/UserCount/UserCountCard';
import UserExpertiseDistribution from '../../../features/UserExpertiseDistribution/UserExpertiseDistribution';
import UserGenderDistribution from '../../../features/UserGenderDistribution/UserGenderDistribution';
import SignUpsChart from '../../../features/UsersOverTime/UsersOverTime';
import tableDataCheck from '../dataTables/variables/tableDataCheck';

export default function AnalyticsPage() {
    // Chakra Color Mode
    const brandColor = useColorModeValue('#0158EB', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            {/* <TimeRangePicker /> */}
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap='20px' mb='20px'>
                <UserCountCard brandColor={brandColor} boxBg={boxBg} />
                <FriendRequestsCount brandColor={brandColor} boxBg={boxBg} />
                <ActivityCountCard brandColor={brandColor} boxBg={boxBg} />
                <ActivityParticipationCount brandColor={brandColor} boxBg={boxBg} />
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1 }} gap='20px' mb='20px'>
                <SignUpsChart />
                {/* <ActivityHeatmap /> */}
                {/* <ActivityBucketsPerTag /> */}
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, md: 2 }} gap='20px' mb='20px'>
                <UserGenderDistribution />
                <UserExpertiseDistribution />
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1 }} gap='20px' mb='20px'>
                {/* <SignUpsChart /> */}
                <ActivityHeatmap />
                <UserAvailabilityHeatmap />
                {/* <ActivityBucketsPerTag /> */}
            </SimpleGrid>
            {/* <SimpleGrid columns={{ base: 1 }} gap='20px' mb='20px'>
                <ActivityBucketsPerDay />
            </SimpleGrid> */}
            {/* <SimpleGrid columns={{ base: 1 }} gap='20px' mb='20px'>
                <ClassesTable tableData={tableDataCheck} />
            </SimpleGrid> */}
        </Box>
    );
}
