import * as Sentry from '@sentry/react';
import { User, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { usePostHog } from 'posthog-js/react';
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useApiClientContext } from '../api/ApiClientProvider';
import { firebaseAuth } from "./firebase.config";
type AuthContextType = { previouslyAuthenticated: boolean, accessToken?: string, currentUser?: User, isAuthenticating: boolean; login: (email: string, password: string) => void; logout: () => void; isLoggingOut: boolean; }
const AuthContext = React.createContext<AuthContextType>({ previouslyAuthenticated: false, isAuthenticating: false, login: () => { }, logout: () => { }, isLoggingOut: false });
export const useAuthContext = () => useContext(AuthContext);

const SERIALIZED_USER_STORAGE_KEY = 'serializedUser';
export const AuthProvider = ({ children }: { children?: React.ReactNode }) => {
    const { apiClient } = useApiClientContext();
    const [currentUser, setCurrentUser] = useState<User>();
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [previouslyAuthenticated, setPreviouslyAuthenticated] = useState(false);
    const postHog = usePostHog();

    useEffect(() => {
        const serializedUser = localStorage.getItem(SERIALIZED_USER_STORAGE_KEY);
        if (serializedUser) {
            // setCurrentUser(JSON.parse(serializedUser));
            setPreviouslyAuthenticated(true);
        }
        // on sign out from

        onAuthStateChanged(firebaseAuth, async (user) => {
            if (user)
                localStorage.setItem(SERIALIZED_USER_STORAGE_KEY, JSON.stringify(user));
            else {
                setPreviouslyAuthenticated(false);
                localStorage.removeItem(SERIALIZED_USER_STORAGE_KEY);
            }


            setCurrentUser(currentUser => {
                Sentry.setUser(user ? {
                    email: user.email,
                    id: user.uid,
                } : null);

                if (user) {
                    postHog.identify(user.uid, { email: user.email, name: user.displayName });
                }
                //We're signing out
                else if (!user && currentUser) {
                    postHog.reset();
                }

                return user
            }
            );
        });
    }, []);


    const logout = useCallback(async () => {
        setIsLoggingOut(true);
        try {
            await signOut(firebaseAuth);
        } catch (error) { }
        setIsLoggingOut(false);
    }, []);

    const login = useCallback(async (email: string, password: string) => {
        setIsAuthenticating(true);
        try {
            const result = await signInWithEmailAndPassword(firebaseAuth, email, password);
        } catch (error) {

        }
        setIsAuthenticating(false);
    }, []);

    useEffect(() => {
        if (apiClient) {
            apiClient.setToken(currentUser ? (currentUser as any).accessToken : '');
        }
    }, [apiClient, currentUser]);


    useEffect(() => {

    }, [currentUser]);

    return (
        <AuthContext.Provider
            value={{
                previouslyAuthenticated,
                logout, isLoggingOut,
                currentUser,
                accessToken: currentUser ? (currentUser as any).accessToken : undefined,
                login,
                isAuthenticating
            }}
        >
            {previouslyAuthenticated && !currentUser ? <div></div> : children}
        </AuthContext.Provider>
    );
};