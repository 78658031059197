// import { Icon } from '@chakra-ui/react';
// Assets
// Custom components
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import Icon from '@mdi/react';
import { useEffect } from 'react';
// import { MdEvent } from 'react-icons/md';
import { useActivityCount } from './useActivityCount';
import { mdiLightningBolt } from '@mdi/js';

type Props = { brandColor: string; boxBg: string; }
export default function ActivityCountCard({ brandColor, boxBg }: Props) {
    const { data, refetch } = useActivityCount();

    useEffect(() => {
        refetch();
    }, []);

    return (
        <MiniStatistics
            startContent={
                <IconBox
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={
                        // null
                        <Icon path={mdiLightningBolt} size={"32px"} color={brandColor} />

                    // <Icon w='32px' h='32px' as={MdEvent}
                    // color={brandColor} />

                }
                />
            }
            name='Activities'
            value={data ? data.count : '--'}
            growth={7}
        />
    )
}
