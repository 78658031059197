import { useQuery } from 'react-query';
import { useApiClientContext } from '../../services/api/ApiClientProvider';
import { useSelectedLocation } from '../LocationPicker/useSelectedLocation';
import { useGetSelectedTimeRange } from '../_shared/hooks/useSelectedTimeRange';

export const useFriendRequestsCount = () => {
    const { startTime, endTime } = useGetSelectedTimeRange();
    const location = useSelectedLocation();
     const {apiClient}  =useApiClientContext();
    return useQuery({
        queryKey: ['friendRequests', location?.id, { startTime, endTime }],
        queryFn: () =>apiClient.getFriendRequestsCount({locationId: location?.id, startTime, endTime})
    });
};