import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from 'react-dom';
import App from './App';
import { ApiClientProvider } from './services/api/ApiClientProvider';
import { AuthProvider } from './services/auth/AuthProvider';
import { getAppConfig } from './services/config/config';
const appConfig = getAppConfig();


ReactDOM.render(
	<PostHogProvider
		apiKey={appConfig.posthog?.apiKey}
		options={{
			api_host: appConfig.posthog?.apiHost,
			autocapture: false,
			disable_session_recording: false
		}}>
		<ApiClientProvider>
			<AuthProvider>
				<App />
			</AuthProvider>
		</ApiClientProvider>
	</PostHogProvider>,
	document.getElementById('root'));
