import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdSpaceDashboard, MdOutlineSpaceDashboard, MdHome, MdOutlineShoppingCart, MdPerson } from 'react-icons/md';
import { RiTableFill,RiTableLine } from "react-icons/ri";
import { HiCreditCard, HiOutlineCreditCard } from "react-icons/hi";
import { IoMailOutline , IoMailSharp} from "react-icons/io5";

// Admin Imports
import DataTables from 'pages/authenticated/dataTables';
import MainDashboard from 'pages/authenticated/default';
import NFTMarketplace from 'pages/authenticated/marketplace';
import Profile from 'pages/authenticated/profile';
import RTL from 'pages/authenticated/rtl';
import AnalyticsPage from './pages/authenticated/analytics/analytics.page';
import ClassesPage from 'pages/authenticated/classes/classes.page';
import { isDemo } from 'services/config/config';
import DemoPage from 'pages/authenticated/demo/demo.page';

// Auth Imports

export const analyticsRoute = '/dashboard';
export const classesRoute = '/classes';
//TODO - Routing: Define route
export const klassRoute = '/klass';
export const billingRoute = '/billing';
export const contactRoute = '/contact';


const appRoutes = [
	{
		name: 'Dashboard',
		layout: analyticsRoute,
		path: '',
		icon: (isActive:boolean)=> <Icon as={isActive?MdSpaceDashboard:MdOutlineSpaceDashboard} width='20px' height='20px' color='inherit' />,
		component: AnalyticsPage
	},
	//TODO - Routing: Append object to appRoutes array
	{
		name: 'Classes',
		layout: classesRoute,
		path: '',
		icon: (isActive:boolean) => {

			// return <Icon as={RiTableLine} width='20px' height='20px' color='inherit' />

			return <Icon as={ isActive? RiTableFill: RiTableLine} width='20px' height='20px' color='inherit' />

		},
		component: ClassesPage
	},
	{
		name: 'Billing',
		layout: billingRoute,
		path: '',
		icon: (isActive:boolean) => {

		return  <Icon as={isActive? HiCreditCard:HiOutlineCreditCard} width='20px' height='20px' color='inherit' />

		 },
		  component: isDemo? DemoPage: ClassesPage
	},
	{
		name: 'Contact',
		layout: contactRoute,
		path: '',
		icon:(isActive:boolean)=>  <Icon as={isActive? IoMailSharp: IoMailOutline} width='20px' height='20px' color='inherit' />,
		component: isDemo? DemoPage: () => <div style={{ width: '100vh', height: '100vh', backgroundColor: 'green' }}>Component</div>
	},
];
const deprecatedRoutes = [
	{
		name: 'Main Dashboard',
		layout: '/admin2',
		path: '/default',
		icon: <Icon as={MdSpaceDashboard} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
	{
		name: 'NFT Marketplace',
		layout: '/admin',
		path: '/nft-marketplace',
		icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
		component: NFTMarketplace,
		secondary: true
	},
	{
		name: 'Data Tables',
		layout: '/admin',
		icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
		path: '/data-tables',
		component: DataTables
	},
	{
		name: 'Profile',
		layout: '/admin',
		path: '/profile',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: Profile
	},
	{
		name: 'RTL Admin',
		layout: '/rtl',
		path: '/rtl-default',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: RTL
	}
]

export default appRoutes;
