// Assets
// Custom components
import { CircularProgress } from '@chakra-ui/react';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { useEffect } from 'react';
import { useTotalUserCount } from './useTotalUserCount';
import { useUserCount } from './useUserCount';

type Props = { brandColor: string; boxBg: string; }
export default function UserCountCard({ brandColor, boxBg }: Props) {
    const { data, refetch } = useUserCount();
    const { data: totalUserCount } = useTotalUserCount();
    useEffect(() => {
        refetch();
    }, []);

    const count = data ? data.count : 0
    const percentage = !totalUserCount ? 0 : 100 * count / totalUserCount;
    return (
        <MiniStatistics
            startContent={

                <IconBox

                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={

                        <CircularProgress value={percentage} size={45} thickness='11px' capIsRound={true} color={brandColor}>
                            {/* <CircularProgressLabel>70%</CircularProgressLabel> */}
                        </CircularProgress>
                    }
                />
            }
            name='Users'
            value={data ? data.count : '--'}
            valueSecondary={`/${totalUserCount ? totalUserCount.toLocaleString() : ' --'}`}
            growth={12}
        />
    )
}





// import React from 'react';

type CircleProps = {
    size: number; // Size of the circle in pixels
    percentage: number; // Fill percentage of the circle
};

const FilledCircle: React.FC<CircleProps> = ({ size, percentage }) => {

    const strokeWidth = size * .1;
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    const circleStyle = {
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#EAEAEA',
    };

    const innerCircleStyle = {
        width: `${size - strokeWidth * 2}px`,
        height: `${size - strokeWidth * 2}px`,
        borderRadius: '50%',
        backgroundColor: 'white',
    };

    const svgStyle = {
        transform: 'rotate(-90deg)',
    };

    return (
        <div style={circleStyle}>
            <svg width={size} height={size} style={svgStyle}>
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    stroke="#007bff"
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                />
            </svg>
            <div style={innerCircleStyle} />

        </div>

    )
};
