import { getMatrixLength } from "./getMatrixLength";
import { indexToMatrixCoordinates } from "./indexToMatrixCoordinates";

export const shiftMatrix = <T>(matrix: T[][], offset: number): T[][] => {
    if (!offset) return matrix;
    const itemCount = matrix.length ? matrix[0].length * matrix.length : 0;
    if ((offset % itemCount) === 0)
        return matrix;

    const clampedOffset = ((offset % itemCount) + itemCount) % itemCount;

    const shiftedMatrix: T[][] = [];
    matrix.forEach(row => {
        shiftedMatrix.push(Array(row.length).fill(0))
    });
    for (let index = 0; index < getMatrixLength(matrix); index++) {
        const { row, col } = indexToMatrixCoordinates(matrix, index);

        const shiftedIndex = index + clampedOffset;
        const { row: shiftedRow, col: shiftedCol } = indexToMatrixCoordinates(matrix, shiftedIndex);

        shiftedMatrix[shiftedRow][shiftedCol] = matrix[row][col];
    }
    return shiftedMatrix;
};