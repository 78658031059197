import { useQuery } from 'react-query';
import { useApiClientContext } from '../../services/api/ApiClientProvider';
import { useSelectedLocation } from '../LocationPicker/useSelectedLocation';
import { useGetSelectedTimeRange } from '../_shared/hooks/useSelectedTimeRange';
export type ClassRow = {
    startTime: Date;
    endTime: Date;
    title: string;
    instructor: string;
    participants: number;
};

export const useClasses = () => {
    const location = useSelectedLocation();
    const { startTime, endTime } = useGetSelectedTimeRange();
    const { apiClient } = useApiClientContext();
    return useQuery({
        queryKey: ['classes', location?.id, { startTime, endTime }], queryFn: () => apiClient.getClasses({ locationId: location?.id, startTime, endTime })
    });
};