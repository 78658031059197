import { localConfig } from "./local.config";
import { prodConfig } from "./prod.config";
import { stagingConfig } from "./staging.config";

export type AppConfig = {
    apiURL: string;
    mixpanel: {
        apiKey: string;
    }
    posthog?: {
        apiKey: string;
        apiHost: string;
    }
}
export type EnvType = 'local' | 'staging' | 'prod';

export const getTargetEnv = () => (process.env.REACT_APP_TARGET_ENV || 'staging') as EnvType;
export const isDemo = () => Boolean(process.env.REACT_APP_IS_DEMO);

export const getAppConfig = (): AppConfig => {
    const env = getTargetEnv();
    switch (env) {
        case "local": return localConfig;
        case "staging": return stagingConfig;
        case "prod": return prodConfig;
    }
}