
// Chakra imports
import { Flex, Box, Text } from '@chakra-ui/react';
// Assets
// Custom components

export default function DemoPage() {

    return (
        <Flex
        align='center' // Vertical center alignment
        justify='center' // Horizontal center alignment
        height='100vh' // Full viewport height
    >
        <Box
            p={4} // Padding inside the Box
            bg='white' // Background color of the Box
            borderRadius={40} // Modern radius
            // boxShadow='xs' // Optional: adds shadow to the Box
            padding={8}
        >
            <Text fontSize='xl' fontWeight='bold'>
                This page is unavailable for demo users.
            </Text>
        </Box>
    </Flex>
    );
}
