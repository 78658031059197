import { useQuery } from 'react-query';
import { useApiClientContext } from '../../services/api/ApiClientProvider';
import { useAuthContext } from '../../services/auth/AuthProvider';
import { useSelectedLocation } from '../LocationPicker/useSelectedLocation';
import { useGetSelectedTimeRange } from '../_shared/hooks/useSelectedTimeRange';
export type BucketedSignupsDto = {
    count: number;
    cumulative: number;
    time: Date;
}[];


export const useUsersOverTime = () => {
    const { accessToken } = useAuthContext();
    const { startTime, endTime } = useGetSelectedTimeRange();
    const location = useSelectedLocation();
    const { apiClient } = useApiClientContext();
    return useQuery({
        queryKey: ['signups', location?.id, { startTime: startTime?.toISOString(), endTime: endTime?.toISOString() }], queryFn: () =>
            apiClient.getBucketedSignups({ locationId: location?.id, startTime, endTime })
    });
};