import { generateRandomTimeFollowingNormalDistribution } from './generateRandomTime';

export interface UserFixture {
    id: number;
    signUpTime: Date;
    gender: Gender;
    expertise: Expertise;
    availabilities: Availability[];
}
export type Availability = {
    startTime: Date;
    endTime: Date;
}

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    PREFERNOTTOANSWER = 'PREFERNOTTOANSWER',
    NONBINARY = 'NONBINARY',
    OTHER = 'OTHER',
}
export enum Expertise {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
}

export const generateUsersFixture = () => {
    const now = new Date();
    const _24hoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    const _7DaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const _1MonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    const _1YearAgo = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000);
    const _2YearsAgo = new Date(now.getTime() - 2 * 365 * 24 * 60 * 60 * 1000);

    const usersCreatedWithin24Hours = generateUsers(_24hoursAgo, now, 20);
    const usersCreatedWithin7Days = generateUsers(_7DaysAgo, now, 50);
    const usersCreatedWithin1Month = generateUsers(_1MonthAgo, now, 200);
    const usersCreatedWithin1Year = generateUsers(_1YearAgo, now, 1000);
    const usersCreatedWithin2Years = generateUsers(_2YearsAgo, now, 2000);

    return [...usersCreatedWithin24Hours, ...usersCreatedWithin7Days, ...usersCreatedWithin1Month, ...usersCreatedWithin1Year, ...usersCreatedWithin2Years];
}

const values: Gender[] = [Gender.MALE, Gender.FEMALE, Gender.NONBINARY, Gender.OTHER];
function generateUsers(startTime: Date, endTime: Date, numUsers: number): UserFixture[] {
    const users: UserFixture[] = [];

    for (let i = 0; i < numUsers; i++) {
        const signUpTime = generateRandomTimeFollowingNormalDistribution(startTime, endTime);
        const expertise = Math.floor(Math.random() * 5) + 1 as Expertise;
        const gender = values[Math.floor(Math.random() * values.length)];
        users.push({
            id: i + 1,
            signUpTime,
            gender,
            expertise,
            availabilities: [{
                //Asigning random availability, between [6am, 8pm] throught random days of the week
                startTime: new Date(2021, 9, Math.floor(Math.random() * 7) + 1,
                    //random time between 6am and 8am
                    Math.floor(Math.random() * 14) + 6, 0, 0),
                endTime: new Date(2021, 9, 1, 6, 0, 0),
            }]
        });
    }

    return users;
}