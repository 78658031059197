import { useQuery } from 'react-query';
import { useApiClientContext } from '../../services/api/ApiClientProvider';
import { useSelectedLocation } from '../LocationPicker/useSelectedLocation';
import { useGetSelectedTimeRange } from '../_shared/hooks/useSelectedTimeRange';
export type ActivityHeatmapDto = number[][];


export const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const useActivityHeatmap = () => {
    const { apiClient } = useApiClientContext();
    const { startTime, endTime } = useGetSelectedTimeRange();
    const location = useSelectedLocation();

    return useQuery({
        queryKey: ['activityHeatmap', location?.id, { startTime: startTime?.toISOString(), endTime: endTime?.toISOString() }],
        queryFn: () => apiClient.getActivityHeatmap({ locationId: location?.id, startTime, endTime })
    });
};