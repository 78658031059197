import { AppConfig } from "./config";

export const prodConfig: AppConfig = {
    apiURL: "https://func-gymspot-prod-eastus2-001.azurewebsites.net/api",
    mixpanel: {
        apiKey: 'd6124c56bcc4be01e69ddadb79a794b9'
    },
    posthog: {
        apiKey: 'phc_3hvbvJCMV0ovoJ4eScYiCCNQU9HlSsLiauxFJK1eH6F',
        apiHost: 'https://rvp-gncrcngydya0g6fz.z01.azurefd.net'
    }
}