// HeatmapComponent.tsx

import { Box, Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { ApexOptions } from 'apexcharts';
import { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import Card from '../../components/card/Card';
import { useActivityHeatmap } from './useActivityHeatmap';
import brand from '../../theme/theme';
import { MdBarChart } from 'react-icons/md';
import { PiExport } from "react-icons/pi";

// import { Icon } from '@chakra-ui/react';

// import { , MdPeople, } from 'react-icons/md';



const ActivityHeatmap = (props: { [x: string]: any }) => {
    const { ...rest } = props;
    const { data: heatmapData, refetch, isLoading } = useActivityHeatmap();

    useEffect(() => {
        refetch();
    }, []);
    const formatHour = (hour:any) => {
        const isPM = hour >= 12;
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        return `${formattedHour} ${isPM ? 'pm' : 'am'}`;
      };

    const allHours = Array.from({ length: 24 }, (_, i) => formatHour(i)); // Full list of 24 hours

    const options: ApexOptions = {
        xaxis: {
            type: 'category',
            categories: Array.from({ length: 24 }, (_, i) => i % 2 === 0 ? formatHour(i) : ""),
            labels: {
                show: true,
            },
        },
        yaxis: {
            // categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            // categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            // labels: { formatter: (val) => { console.log('formatting Y', val); return `${val}-XYZ` } }
            // type: 'category',
            // categories: Array.from({ length: 24 }, (_, i) => `${i}:00`),
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            x: {
                // show: true,
                formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
                    return allHours[dataPointIndex]; // Use full list for tooltip
                },
            },

        },

        chart: {
            toolbar: {
                show: false, // until i can add a custom icon, will hide
                tools:{
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [{
                        icon: '<script src="https://kit.fontawesome.com/a076d05399.js" crossorigin="anonymous"></script>',
                        title: 'Download Chart',
                        index: -1,
                        class: 'custom-download-icon',
                        click: (chart:any) => {
                            chart.exportToPng({
                                filename: "aHealthy Company Activities Heat Chart"
                            });
                        }
                    }]
            }}
        },

        colors: [brand.colors.brand[500]],
    };
    // Chakra Color Mode
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
    const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

    return (
        <Card alignItems='center' flexDirection='column' w='100%' {...rest}>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='2xl' fontWeight='700' lineHeight='100%'>
                    Activities
                </Text>
                {Boolean(!heatmapData && isLoading) && <Button
                    isLoading={isLoading}
                    alignItems='center'
                    justifyContent='center'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    borderRadius='10px'
                    {...rest}>
                    <Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
                </Button>}
            </Flex>

            <Box h='350px' mt='auto' w='100%'>
                <ReactApexChart
                    options={options}
                    series={heatmapData || []}
                    type="heatmap"
                    height={350}
                />
            </Box>
        </Card>
    );
};

export default ActivityHeatmap;