import { getMatrixLength } from "./getMatrixLength";

export const indexToMatrixCoordinates = <T>(matrix: T[][], index: number) => {
    const length = getMatrixLength(matrix);
    const rowCount = matrix.length;
    const colCount = matrix.length ? matrix[0].length : 0;

    const clampedIndex = index % length;
    const row = Math.floor(clampedIndex / colCount);
    const col = clampedIndex % colCount;

    return { row, col };
};