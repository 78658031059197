import { ClassRow } from "../../../features/Classes/useClasses";

export function generateClassesFixture(): ClassRow[] {
    return [...generateClasses(0, 5), ...generateClasses(1, 20), ...generateClasses(5, 25)];
}

function generateClasses(weeksAgo: number, numClasses: number) {
    const day = new Date();
    //subtracting weeksAgo weeks from today
    day.setDate(day.getDate() - weeksAgo * 7);
    const classes: ClassRow[] = [];
    for (let i = 0; i < numClasses; i++) {
        const instructor = Math.random() > 0.5 ? 'John Doe' : 'Jane Doe';
        const activity = Math.random() > 0.5 ? 'Yoga' : 'Crossfit';
        const startTime = new Date(day.getFullYear(), day.getMonth(), day.getDate() + Math.floor(Math.random() * 30), 8 + Math.floor(Math.random() * 10), 0, 0);
        const endTime = new Date(startTime.getTime() + 60 * 60 * 1000);
        classes.push({
            instructor,
            participants: Math.floor(Math.random() * 10),
            // activity,
            title: activity,
            startTime, endTime
        });
    }
    return classes;
}