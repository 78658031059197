// Chakra imports
import { Flex, Stat, StatLabel, StatNumber, Text, useColorModeValue, StatHelpText,StatArrow, StatGroup, Tooltip } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

export default function Default(props: {
	startContent?: JSX.Element;
	endContent?: JSX.Element;
	name: string;
	valueSecondary?: string|number;
	growth?: string | number;
	value: string | number;
}) {
	const { startContent, endContent, name, growth, value, valueSecondary } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	const rowStyle = {
        display: 'flex',
        alignItems: 'center',
		justifyContent: 'space-between',
		gap: '6px',
    };

	const valueStyle= {
		display: 'flex',
        alignItems: 'center',
		gap: '6px',
    };

	return (
		<Card py='15px'>
			<Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
				{startContent}

				<Stat my='auto' ms={startContent ? '18px' : '0px'}>
					<StatLabel
						lineHeight='100%'
						color={textColorSecondary}
						fontSize={{
							base: 'sm'
						}}>
						{name}
					</StatLabel>
					<Flex style={rowStyle}>

					<Flex style={valueStyle}>
								<StatNumber
									color={textColor}
									fontSize={{
										base: '2xl'
									}}>
									{value.toLocaleString()}
								</StatNumber>
								<StatNumber
									lineHeight='100%'
									color={textColorSecondary}
									fontSize={{
										base: 'xs'
									}}>
									{valueSecondary?valueSecondary:null}
								</StatNumber>
					</Flex>

						{/* { (growth<0 ||growth >0) &&

								<Tooltip  label={`${growth}% ${growth >0?'increase':'decrease'} compared to last month`} bg={"#5a5a5a"}>

								<StatGroup>
									<StatHelpText>
									<StatArrow type={ growth >0?'increase':'decrease'} />
										{growth}%
									</StatHelpText>
								</StatGroup>
							</Tooltip>
								} */}

					</Flex>
				</Stat>
				<Flex ms='auto' w='max-content'>
					{endContent}
				</Flex>
			</Flex>
		</Card>
	);
}
