import { useQuery } from 'react-query';
import { useApiClientContext } from '../../services/api/ApiClientProvider';
import { useSelectedLocation } from '../LocationPicker/useSelectedLocation';
export type UserAvailabilityHeatmapDto = number[][];


export const useAvailabilityHeatmap = () => {
    const location = useSelectedLocation();
    const { apiClient } = useApiClientContext();
    return useQuery({
        queryKey: ['availabilityHeatmap', location?.id],
        queryFn: () => apiClient.getAvailabilityHeatmap({ locationId: location?.id })
    });
};