/* eslint-disable */

import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { isActiveRoute } from '../../../layouts/admin';

export function SidebarLinks(props: {
	routes: RoutesType[];
}) {
	//   Chakra color mode
	let location = useLocation();
	let activeColor = useColorModeValue('gray.700', 'white');
	let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
	let activeIcon = useColorModeValue('brand.500', 'white');
	let textColor = useColorModeValue('secondaryGray.500', 'white');
	let brandColor = useColorModeValue('brand.500', 'brand.400');

	const { routes } = props;

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName: string) => {
		const result = location.pathname.includes(routeName);
		// console.log(`Checking [${routeName}] against [${location.pathname}] returns ${result}`);
		return result;
	};

	// this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
	const createLinks = (
		routes: RoutesType[],
	) => {
		return routes.map(
			(
				route: RoutesType,
				index: number
			) => {
				if (true) {
					// if (route.layout === analyticsRoute || route.layout === '/auth' || route.layout === '/rtl') {
					return (
						<NavLink key={index} to={route.layout + route.path}>
							{route.icon ? (
								<Box alignItems='center' justifyContent='center'>
    <HStack
        spacing='30px'
        py='5px'
        bg={isActiveRoute(route) ? "#F3F7FB" : 'transparent'}
        borderRadius={isActiveRoute(route) ? '18px' : '0px'}
        width="95%"
        ps='10px'
        alignItems='center'
        justifyContent='center'>

        <Flex w='100%' alignItems='center' justifyContent='center'>
            {/* Ensure the icon Box doesn't push other elements to the side */}
            <Box
                color={isActiveRoute(route) ? activeIcon : textColor}
                me='18px'
                display='flex'  // Make this a flex container
                alignItems='center'>  {/* Center the icon vertically */}

                {route.icon(isActiveRoute(route))}
            </Box>
            <Text
                me='auto'
                color={isActiveRoute(route) ? activeColor : textColor}
                fontWeight={isActiveRoute(route) ? 'bold' : 'normal'}>
                {route.name}
            </Text>
        </Flex>

        {/* This Box seems to be for decoration, ensure it doesn't affect the centering */}
        <Box
            h='50px'
            w='4px'
            bg={isActiveRoute(route) ? 'transparent' : 'transparent'}
            borderRadius='5px'
        />
    </HStack>
</Box>

							) : (
								<Box>
									<HStack
										spacing={isActiveRoute(route) ? '22px' : '26px'}
										py='5px'
										ps='10px'>
										<Text
											me='auto'
											color={isActiveRoute(route) ? activeColor : inactiveColor}
											fontWeight={isActiveRoute(route) ? 'bold' : 'normal'}>
											{route.name}
										</Text>
										<Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
									</HStack>
								</Box>
							)
							}
						</NavLink >
					);
				}
			}
		);
	};
	//  BRAND
	return <>{createLinks(routes)}</>
}

export default SidebarLinks;
