// TimeRangePicker.tsx
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { subDays, subMonths } from 'date-fns';
import { subWeeks, subYears } from 'date-fns/esm';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { SelectedTimeRange, useGetSelectedTimeRange, useSetSelectedTimeRange } from '../_shared/hooks/useSelectedTimeRange';
import { FiCalendar , FiChevronDown} from "react-icons/fi";

interface TimeRangePickerProps {
    onSelectRange: (startDate: Date, endDate: Date) => void;
}
type SelectedTimeRangeTitleType = 'All Time' | 'Today' | 'Last 24 Hours' | 'Last Week' | 'Last Month' | 'Last Year' | 'Custom';
const options: { title: SelectedTimeRangeTitleType; getTimeRange: () => SelectedTimeRange }[] = [
    {
        title: 'All Time', getTimeRange: () => ({})
    },
    {
        title: 'Today',
        getTimeRange: () => {
            const startTime = new Date();
            startTime.setHours(0, 0, 0, 0);
            return { startTime };
        }
    },
    {
        title: 'Last 24 Hours',
        getTimeRange: () => {
            return { startTime: subDays(new Date(), 1) };
        }
    },
    {
        title: 'Last Week',
        getTimeRange: () => {
            return { startTime: subWeeks(new Date(), 1) };
        }
    },
    {
        title: 'Last Month',
        getTimeRange: () => {
            return { startTime: subMonths(new Date(), 1) };
        }
    },
    {
        title: 'Last Year',
        getTimeRange: () => {
            return { startTime: subYears(new Date(), 1) };
        }
    }
];
const TimeRangePicker = () => {
    const { startTime, endTime } = useGetSelectedTimeRange();
    const setSelectedTimeRange = useSetSelectedTimeRange();
    const [selectedTimeRangeTitleType, setSelectedTimeRangeTitleType] = useState<SelectedTimeRangeTitleType>('All Time');

    return (
        <Menu >
            <MenuButton>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 10, marginRight:15, marginLeft:15, }}>
                    <FiCalendar style={{ color: "#8892b0" }}  size={20}/>
                    {/* {selectedTimeRangeTitleType} */}
                    <span style={{ color: "#8892b0", fontWeight:600, }}>{selectedTimeRangeTitleType}</span>

                    <FiChevronDown style={{ color: "#8892b0" }}  size={20} />

                    {/* <ChevronDownIcon style={{ color: "#A3AED0" }} /> */}
                </div>
            </MenuButton>
            <MenuList>
                {options.map(option => {
                    return <MenuItem onClick={() => {
                        setSelectedTimeRangeTitleType(option.title);
                        setSelectedTimeRange(option.getTimeRange());
                    }}>{option.title}</MenuItem>
                })}
            </MenuList>
        </Menu>
    );
};

export default TimeRangePicker;