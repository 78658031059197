import * as firebase from "firebase/app";
import "firebase/auth";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyBbpqm4krYUQ6MvtCti6vhbBXd-nftSXOY",
	authDomain: "prod-portal-e0fd3.firebaseapp.com",
	projectId: "prod-portal-e0fd3",
	storageBucket: "prod-portal-e0fd3.appspot.com",
	messagingSenderId: "1044788386925",
	appId: "1:1044788386925:web:41f644e259ae88c659f364",
	measurementId: "G-MYP3BVQZY4"
};
const firebaseApplication = firebase.initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApplication);
export { firebaseApplication, firebaseAuth };
