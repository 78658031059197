import { useCallback } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

export type SelectedTimeRange = { startTime?: Date; endTime?: Date; }
const selectedTimeRange = atom<SelectedTimeRange>({
    key: 'selectedTimeRange',
    default: {},
});

export const useGetSelectedTimeRange = ()=> useRecoilValue(selectedTimeRange);
export const useSetSelectedTimeRange = () => {
    const setState = useSetRecoilState(selectedTimeRange);
    const updateValue = useCallback((newValue: SelectedTimeRange) => {
        const newStartTime = newValue.startTime;
        const newEndTime = newValue.endTime;

        if (newStartTime) newStartTime.setSeconds(0, 0);
        setState({ startTime: newStartTime, endTime: newEndTime });
    }, [setState]);

    return updateValue;
};
