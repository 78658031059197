import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FiChevronDown } from "react-icons/fi";
import { useLocations } from './useLocations';
import { useSelectedLocation, useSetSelectedLocation } from './useSelectedLocation';

export default function LocationSelecter({ brandText, color }: { brandText: any, color: any }) {
    const { data: locations, refetch } = useLocations();
    const setSelectedLocation = useSetSelectedLocation();
    const selectedLocation = useSelectedLocation();

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (!selectedLocation && locations && locations.length)
            setSelectedLocation(locations[0])
    }, [locations]);

    // return (
    //     <Select value={(selectedLocation as any)?.id} fontSize='sm' variant='subtle' defaultValue='monthly' width='unset' fontWeight='700' onChange={(event) => {
    //         setSelectedLocation(locations.find(location => location.id === event.target.value));
    //     }}>
    //         {(locations || []).map(location => {
    //             return <option value={location.id}>{location.fullname}</option>
    //         })}
    //     </Select>
    // );

    return (
        <Menu>
            <MenuButton as={Button}
                fontSize='sm'
                variant='subtle'
                fontWeight='700'
                display='flex' // This ensures the button contents use flexbox
                alignItems='center' // This centers the button contents vertically
                justifyContent='center' // This centers the button contents horizontally
                height='auto' // Adjust the height as necessary to ensure proper spacing>
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>

                    <span style={{
                        color: color,
                        fontWeight: 700,
                        fontSize: '34px',
                        marginRight: 10,
                    }}>

                    {selectedLocation ? selectedLocation.fullname : "Select Location"}
                </span>
                <FiChevronDown style={{ color: "#8892b0" }}  size={20} />
                </div>


            </MenuButton>
            <MenuList>
                {(locations || []).map(location => (
                    <MenuItem key={location.id} onClick={() => setSelectedLocation(location)}>
                        {location.fullname}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
}
