import { getAppConfig } from "../config/config";

export const isResponseValid = (statusCode: number) =>
    statusCode >= 200 && statusCode < 300;

export type RequestProps = {
    endpoint: string;
    token: string;
    onUnauthorized: () => void;
    data?: any;
    contentType: string;
    additionalHeaders: any;
    method: string;
};
export const postJson = async <TOutput>(
    options: Partial<RequestProps>,
) => {
    const { json } = await sendRequest<TOutput>(options);
    return json;
}
export const sendRequest = async <TOutput>(
    options: Partial<RequestProps>,
) => {
    const {
        method,
        endpoint,
        onUnauthorized,
        contentType,
        additionalHeaders,
        token,
        data,
    } = {
        onUnauthorized: () => { },
        contentType: 'application/json',
        additionalHeaders: {},
        method: 'POST',
        ...options,
    };
    const result = await fetch(`${getAppConfig().apiURL}/${endpoint}`, {
        method,
        body: method === 'GET' ? undefined : JSON.stringify(data || {}),
        headers: {
            ...additionalHeaders,
            'Content-Type': contentType,
            Authorization: token,
        },
    });

    if (result.status === 401) onUnauthorized();
    const jsonResponse = await result.json();
    if (!isResponseValid(result.status)) {
        throw new Error(jsonResponse.message);
    }

    return {
        status: result.status,
        json: jsonResponse as TOutput,
    };
};