// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
// Custom components
// import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
	// Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white') as string;

	// Require the logo image
	// const logoImage = require('../../../assets/img/logo/aHealthyCompanyLogo.png').default;

	return (
		<Flex
		 alignItems='center'
		 justifyContent='center'
		 flexDirection='column' >
			{/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
			{/* <Image src={logoImage} /> */}
			{/* <Box boxSize='sm'> */}
			{/* </Box> */}
  		<Image src = "./aHealthyCompanyLogo.png" alt='aHealthy Company Logo' width={"60%"}   />
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
