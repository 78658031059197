import * as Sentry from "@sentry/react";
import { getTargetEnv } from "../config/config";

export const initializeSentry = () => {
    const environment = getTargetEnv();
    if(environment === 'local')return;
    Sentry.init({
        dsn: "https://8b6e96c0800355ecbc2abe155a2c6a0f@o1404847.ingest.sentry.io/4506252912885760",
        integrations: [
            new Sentry.BrowserTracing(),
        ],
        environment,
        tracesSampleRate: 0.25,
    });
};