import { BucketedActivitiesDto } from "../../features/ActivityBuckets/useActivityBuckets";
import { ActivityCountDto } from "../../features/ActivityCount/useActivityCount";
import { ActivityHeatmapDto, daysOfWeek } from "../../features/ActivityHeatmap/useActivityHeatmap";
import { ClassRow } from "../../features/Classes/useClasses";
import { WorkoutLocation } from "../../features/LocationPicker/useLocations";
import { UserAvailabilityHeatmapDto } from "../../features/UserAvailabilityHeatmap/useAvailabilityHeatmap";
import { UserCountDto } from "../../features/UserCount/useUserCount";
import { BucketedSignupsDto } from "../../features/UsersOverTime/useBucketedSignups";
import { shiftMatrix } from "../../features/_shared/utils/matrix/shiftMatrix";
import { timeRangeToQueryParams } from "../../features/_shared/utils/timeRangeToQueryParams";
import { IAPIClient } from "./IAPIClient";
import { postJson } from "./sendRequest";

export class RestApiClient extends IAPIClient {
    async getAvailabilityHeatmap({ locationId }: { locationId: string; }): Promise<{ data: number[]; name: string }[]> {
        const heatmapData = await postJson<UserAvailabilityHeatmapDto>({
            method: 'GET',
            data: {},
            token: this.token,
            endpoint: `analytics/availabilities/${locationId}`
        });
        if (!heatmapData) return [];

        //The backend is sending UTC timeranges. But each client might be in a different timezone
        //We're shifting the received heatmap data by X-hours, where X is the offset from UTC
        const offsetInHours = -Math.floor(new Date().getTimezoneOffset() / 60);
        const shiftedHeatmap = shiftMatrix(heatmapData, offsetInHours);

        return shiftedHeatmap.map((dayOfWeekData: number[], index: number) => {
            return {
                data: dayOfWeekData,
                name: daysOfWeek[index]
            }
        });

    }
    async getBucketedSignups({ locationId, startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<BucketedSignupsDto> {
        return postJson<BucketedSignupsDto>({
            method: 'GET',
            data: {},
            token: this.token,
            endpoint: `analytics/signups/${locationId}?${timeRangeToQueryParams({ startTime, endTime })}`
        })
    }
    async getUserCount({ locationId, startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<UserCountDto> {
        return postJson<UserCountDto>({
            method: 'GET',
            data: {},
            token: this.token,
            endpoint: `analytics/usercount/${locationId}?${timeRangeToQueryParams({ startTime, endTime })}`
        })
    }
    async getLocations(): Promise<WorkoutLocation[]> {
        return postJson<WorkoutLocation[]>({
            method: 'GET',
            data: {},
            token: this.token,
            endpoint: 'locations'
        });
    }
    async getFriendRequestsCount({ locationId, startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<{ count: number; }> {
        return postJson<{ count: number }>({
            method: 'GET',
            data: {},
            token: this.token,
            endpoint: `analytics/friendrequests/${locationId}?${timeRangeToQueryParams({ startTime, endTime })}`
        })
    }
    async getClasses({ locationId, startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<ClassRow[]> {
        return postJson<ClassRow[]>({
            method: 'GET',
            data: {},
            token: this.token,
            endpoint: `analytics/classes/${locationId}?${timeRangeToQueryParams({ startTime, endTime })}`
        })
    }

    async getActivityCount({ locationId, startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<ActivityCountDto> {
        return postJson<ActivityCountDto>({
            method: 'GET',
            data: {},
            token: this.token,
            endpoint: `analytics/activitycount/${locationId}?${timeRangeToQueryParams({ startTime, endTime })}`
        });
    }
    async getActivityHeatmap({ locationId, startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<{ data: number[]; name: string }[]> {
        const heatmapData = await postJson<ActivityHeatmapDto>({
            method: 'GET',
            data: {},
            token: this.token,
            endpoint: `analytics/activityheatmap/${locationId}?${timeRangeToQueryParams({ startTime, endTime })}`
        });
        if (!heatmapData) return [];

        //The backend is sending UTC timeranges. But each client might be in a different timezone
        //We're shifting the received heatmap data by X-hours, where X is the offset from UTC
        const offsetInHours = -Math.floor(new Date().getTimezoneOffset() / 60);
        const shiftedHeatmap = shiftMatrix(heatmapData, offsetInHours);

        return shiftedHeatmap.map((dayOfWeekData: number[], index: number) => {
            return {
                data: dayOfWeekData,
                name: daysOfWeek[index]
            }
        });
    }
    getActivityBuckets({ locationId, startTime, endTime }: { locationId: string; startTime: Date; endTime: Date; }): Promise<BucketedActivitiesDto> {
        return postJson<BucketedActivitiesDto>({
            method: 'GET',
            data: {},
            endpoint: `analytics/activitybuckets/${locationId}?${timeRangeToQueryParams({ startTime, endTime })}`
        })
    }
}