import { Icon } from '@chakra-ui/react';
// Assets
// Custom components
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { useEffect } from 'react';
import { MdBarChart, MdPeople, } from 'react-icons/md';

import { useActivityCount } from '../ActivityCount/useActivityCount';

type Props = { brandColor: string; boxBg: string; }
export default function ActivityParticipationCount({ brandColor, boxBg }: Props) {
    const { data, refetch } = useActivityCount();

    useEffect(() => {
        refetch();
    }, []);

    return (
        <MiniStatistics
            startContent={
                <IconBox
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={<Icon w='32px' h='32px' as={MdPeople} color={brandColor} />}

                    // icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}
                />
            }
            name='Participation'
            value={data ? data.participationCount : '--'}
            growth={48}

        />
    )
}
