import ReactApexChart from 'react-apexcharts';

type ChartProps<TData, TOptions> = {
	chartData: TData;
	chartOptions: TOptions;
	[x: string]: any;
};

const DonutChart = ({ chartData, chartOptions }: ChartProps<ApexAxisChartSeries | ApexNonAxisChartSeries, ApexCharts.ApexOptions>) => {
	return (<ReactApexChart
		options={chartOptions}
		series={chartData}
		type='donut'
		width='100%'
		height='55%'
	/>)
};

export default DonutChart;
