import { useQuery, useQueryClient } from 'react-query';
import { useApiClientContext } from '../../services/api/ApiClientProvider';
import { useSelectedLocation } from '../LocationPicker/useSelectedLocation';
import { useGetSelectedTimeRange } from '../_shared/hooks/useSelectedTimeRange';
export type UserCountDto = {
    count: number;
    totalCount: number;
    genderDistribution: { [key: string]: number };
    expertiseDistribution: { [key: string]: number };
};


export const useUserCount = () => {
    const queryClient = useQueryClient();
    const { startTime, endTime } = useGetSelectedTimeRange();
    const location = useSelectedLocation();
    const { apiClient } = useApiClientContext();
    return useQuery({
        queryKey: ['userCount', location?.id, { startTime, endTime }], queryFn: () => apiClient.getUserCount({ locationId: location?.id, startTime, endTime }),
        onSuccess: (data) => {
            queryClient.setQueryData(['totalUserCount', location?.id], () => data.totalCount);
        }
    });
};