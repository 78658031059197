// LineChartComponent.tsx

import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { useEffect, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { MdBarChart } from 'react-icons/md';
import Card from '../../components/card/Card';
import { useUsersOverTime } from './useBucketedSignups';
import moment from 'moment';


const SignUpsChart = (props: { [x: string]: any }) => {
    const { ...rest } = props;
    const { data: lineChartData, isLoading, refetch } = useUsersOverTime();


    const seriesData: ApexAxisChartSeries | ApexNonAxisChartSeries = useMemo(() => {
        return [
        //     {
        //     name: 'Sign-ups', data: (lineChartData || []).map(d => {
        //         return [new Date(d.time).getTime(), d.count]
        //     })
        // },
         {
            name: 'Users', data: (lineChartData || []).map(d => {
                return [new Date(d.time).getTime(), d.cumulative]
            })
        }];
    }, [lineChartData]);

    useEffect(() => {
        refetch();
    }, []);

    const options: ApexCharts.ApexOptions = {
        // chart: {
        //     type: 'line',
        // },
        xaxis: {
            // type: 'datetime',
            tickAmount: 5,
            labels: {
                formatter: (val) => {
                    const date = new Date(val);
                    return moment(val).format("MMM DD");
                }
            }

        },
        tooltip: {
            x: {
                // show: true,
                formatter: (val) => {
                    const date = new Date(val);
                    return moment(val).format("MMM DD YY");
                },
            },

        },
        yaxis: {
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.3,
                opacityTo: 0.01,
                stops: [20, 100, 100, 100]
              },
            },
            chart: {
                toolbar: {
                    show: false, // until i can add a custom icon, will hide
                    tools: {
                            zoom:false,
                            selection:false,
                    },
                }

            },
    };

    // Chakra Color Mode
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
    const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

    return (
        <Card alignItems='center' flexDirection='column' w='100%' {...rest}>
            <Flex align='center' w='100%' px='15px' py='10px'>
                <Text me='auto' color={textColor} fontSize='2xl' fontWeight='700' lineHeight='100%'>
                    Users
                </Text>
                {Boolean(!lineChartData && isLoading) && <Button
                    isLoading={isLoading}
                    alignItems='center'
                    justifyContent='center'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    borderRadius='10px'
                    {...rest}>
                    <Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
                </Button>}
            </Flex>

            <Box h='350px' mt='auto' w='100%'>
                <ReactApexChart
                    options={options}
                    series={seriesData}
                    type="area"
                    height={350}
                />
            </Box>
        </Card>
    );
};

export default SignUpsChart;