import { generateRandomTimeFollowingNormalDistribution } from "./generateRandomTime";

export type ActivityFixture = {
    id: number;
    startTime: Date;
    endTime: Date;
};

export function generateActivitiesFixture() {
    const now = new Date();
    const _2YearsAgo = new Date(now.getTime() - 2 * 365 * 24 * 60 * 60 * 1000);

    const tuples = generateDateTuples(_2YearsAgo, now)
    const ACTIVITY_COUNT_ON_WEEK_DAYS = 20;
    const ACTIVITY_COUNT_ON_WEEKENDS = 12;
    const activities = tuples.map(([startTime, endTime]) => generateActivities(startTime, endTime, (startTime.getDay() === 0 || startTime.getDay() === 6) ? ACTIVITY_COUNT_ON_WEEKENDS : ACTIVITY_COUNT_ON_WEEK_DAYS)).flat();
    return activities;
}
function generateDateTuples(startTime: Date, endDate: Date) {
    const dateList: Date[][] = [];
    let currentDate = new Date(startTime);

    while (currentDate <= endDate) {
        dateList.push([
            new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 6, 0, 0),
            new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 20, 0, 0),
        ]);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateList;
}
function generateActivities(startTime: Date, endTime: Date, numActivities: number): ActivityFixture[] {
    const activities: ActivityFixture[] = [];

    for (let i = 0; i < numActivities; i++) {
        const activityStartTime = generateRandomTimeFollowingNormalDistribution(startTime, endTime);
        activities.push({ id: i + 1, startTime: activityStartTime, endTime: new Date(activityStartTime.getTime() + 60 * 60 * 1000) });
    }

    return activities;
}