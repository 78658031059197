import { useQuery, useQueryClient } from 'react-query';
import { WorkoutLocation } from './useLocations';

export const useSelectedLocation = () => {
    return useQuery<WorkoutLocation>(['selectedLocation']).data
};

export const useSetSelectedLocation = () => {
    const queryClient = useQueryClient();
    return (selectedLocation: WorkoutLocation) => {
        queryClient.setQueriesData(['selectedLocation'], (updater) => selectedLocation);
    };
};