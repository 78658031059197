// Chakra imports
import { Box, Flex, Text, useColorModeValue, SimpleGrid } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { VSeparator } from "components/separator/Separator";
import { keys, sum, values } from "lodash";
import { useMemo } from "react";
import DonutChart from "../../components/charts/DonutChart";
import { pieChartOptions } from "../../variables/charts";
import { useUserCount } from "../UserCount/useUserCount";

export default function UserGenderDistribution(props: { [x: string]: any }) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const { data } = useUserCount();

  const chartData = useMemo(() => {
    if (!data) return [];

    const vals = keys(data.genderDistribution).map((key) => {
      let label: string;
      if (key === "PREFERNOTTOANSWER") label = "No Answers";
      else label = key[0] + key.substring(1).toLowerCase().replace("_", " ");
      return {
        count: data.genderDistribution[key],
        label,
      };
    });
    const total = sum(values(data.genderDistribution));

    const result = vals
      .sort((a, b) => b.count - a.count)
      .map((item) => {
        return {
          count: Math.floor(100 * (item.count / total)),
          label: item.label,
        };
      });
    return result;
  }, [data]);

  return (
    <Card
      p="20px"
      alignItems="center"
      flexDirection="column"
      w="100%"
      {...rest}
    >
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="2xl" fontWeight="600" mt="4px">
          Gender
        </Text>
      </Flex>
      <DonutChart
        h="100%"
        w="100%"
        chartData={chartData.map((l) => l.count)}
        chartOptions={{
          ...pieChartOptions,
          labels: chartData.map((l) => l.label),
        }}
      />


<Card
      bg={cardColor}
      boxShadow={cardShadow}
      w="100%"
      p="15px"
      px="20px"
      mt="15px"
      mx="auto"
      display="flex" // Ensure the Card is a flex container
      justifyContent="center" // Center the SimpleGrid horizontally within the Card
      alignItems="center" // Center the SimpleGrid vertically within the Card
    >
      <SimpleGrid
        columns={{ base: chartData.length, md: Math.floor(chartData.length/2), lg: chartData.length }}
        gap='20px'
        width="full" // Set the width of the grid to auto
        justifyContent="center" // Center the grid items horizontally (applies when there's extra space)
      >
        {chartData.map((data, index) => (
          <Flex direction="column" py="4px" key={data.label} align="center">




          <Flex
          // bg="green"
          align="center" direction="row">
            <Box
              h="8px"
              w="8px"
              bg={ pieChartOptions.colors[index]}
              borderRadius="50%"
              me="px"
            />
            <Text
              // bg="red"
              fontSize="xs"
              color="secondaryGray.600"
              fontWeight="700"
              marginLeft={"2px"}
              alignSelf="center" // Centers this text vertically within the Flex container
              mb="0" // Adjust or remove the margin to improve alignment
            >
              {data.label}
            </Text>
          </Flex>






            <Text fontSize="md" color={textColor} fontWeight="700">
              {data.count}%
            </Text>


          </Flex>

        ))}
      </SimpleGrid>
    </Card>


    </Card>
  );
}


// {/* <Card
// bg={cardColor}
// flexDirection="row"
// boxShadow={cardShadow}
// w="100%"
// p="15px"
// px="20px"
// mt="15px"
// mx="auto"
// >
// {/* {chartData.length && (
//   <Flex direction="column" py="5px">
//     <Flex align="center">
//       <Box h="8px" w="8px" bg="brand.500" borderRadius="50%" me="4px" />
//       <Text
//         fontSize="xs"
//         color="secondaryGray.600"
//         fontWeight="700"
//         mb="5px"
//       >
//         {chartData[0].label}
//       </Text>
//     </Flex>
//     <Text fontSize="lg" color={textColor} fontWeight="700">
//       {chartData[0].count}%
//     </Text>
//   </Flex>
// )}
// <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />
// {chartData.length > 1 && (
//   <Flex direction="column" py="5px" me="10px">
//     <Flex align="center">
//       <Box h="8px" w="8px" bg="#6AD2FF" borderRadius="50%" me="4px" />
//       <Text
//         fontSize="xs"
//         color="secondaryGray.600"
//         fontWeight="700"
//         mb="5px"
//       >
//         {chartData[1].label}
//       </Text>
//     </Flex>
//     <Text fontSize="lg" color={textColor} fontWeight="700">
//       {chartData[1].count}%
//     </Text>
//   </Flex>
// )} */}


// <Flex
// justifyContent="center" // Centers children horizontally
// // alignItems="center" // Centers children vertically
// width={"100%"}
// gap={2}
// wrap="wrap" // Allows children to wrap onto the next line if they don't fit
// >


// {chartData.map((data, index) => (
// <>
// <Flex direction="column" py="5px" key={data.label}>
// <Flex align="center">
// <Box
//   h="8px"
//   w="8px"
//   bg={index === 0 ? "brand.500" : "#6AD2FF"} // Use a different color for the first item
//   borderRadius="50%"
//   me="4px"
// />
// <Text fontSize="xs" color="secondaryGray.600" fontWeight="700" mb="5px">
//   {data.label}
// </Text>
// </Flex>
// <Text fontSize="lg" color={textColor} fontWeight="700">
// {data.count}%
// </Text>
// </Flex>
// {index < chartData.length - 1 && <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />}
// </>
// ))}
// </Flex>

// </Card> */}