import { ChakraProvider } from '@chakra-ui/react';
import 'firebase/auth';
import SignInCentered from 'pages/anonymous/signIn/index';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './assets/css/App.css';
import AdminLayout from './layouts/admin';
import AuthLayout from './layouts/auth';
import RTLLayout from './layouts/rtl';
import { analyticsRoute, billingRoute, classesRoute, contactRoute } from './routes';
import { useAuthContext } from './services/auth/AuthProvider';
import { isDemo } from './services/config/config';
import { initializeSentry } from './services/sentry/sentry.service';
import theme from './theme/theme';
// Auth Imports
const queryClient = new QueryClient();

export default function App() {
    const { currentUser } = useAuthContext();
    useEffect(() => {
        initializeSentry();
    }, []);

    return (
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={theme}>
                    <React.StrictMode>
                        <BrowserRouter>
                            {(isDemo() || currentUser) ?
                                (
                                    <Switch>
                                        <Route path={`/auth`} component={AuthLayout} />
                                        <Route path={analyticsRoute} component={AdminLayout} />
                                        <Route path={classesRoute} component={AdminLayout} />
                                        {/* //TODO - Routing: Add <Route /> */}
                                        {/* <Route path={klassRoute} component={AdminLayout} /> */}
                                        <Route path={billingRoute} component={AdminLayout} />
                                        <Route path={contactRoute} component={AdminLayout} />

                                        <Route path={`/rtl`} component={RTLLayout} />
                                        <Redirect from='/' to={analyticsRoute} />
                                    </Switch>
                                ) : <Switch>
                                    <Route path='/signin' component={SignInCentered} />
                                    <Redirect to='/signin' />
                                </Switch>}
                        </BrowserRouter>
                    </React.StrictMode>
                </ChakraProvider>
            </QueryClientProvider>
        </RecoilRoot>
    )
}