// Chakra imports
import { Box, Flex, Heading } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAuth';
import PropTypes from 'prop-types';
// Custom components
// Assets

function AuthIllustration(props: { children: JSX.Element | string; illustrationBackground: string }) {
	const { children, illustrationBackground } = props;
	// Chakra color mode
	return (
		<Flex position='relative' h='max-content'>
			<Flex
				h={{
					sm: 'initial',
					md: 'unset',
					lg: '100vh',
					xl: '97vh'
				}}
				w='100%'
				maxW={{ md: '66%', lg: '1313px' }}
				mx='auto'
				pt={{ sm: '50px', md: '0px' }}
				px={{ lg: '30px', xl: '0px' }}
				ps={{ xl: '70px' }}
				justifyContent='start'
				direction='column'>
				{/* <NavLink
					to='/admin'
					style={() => ({
						width: 'fit-content',
						marginTop: '40px'
					})}>
					<Flex align='center' ps={{ base: '25px', lg: '0px' }} pt={{ lg: '0px', xl: '0px' }} w='fit-content'>
						<Icon as={FaChevronLeft} me='12px' h='13px' w='8px' color='secondaryGray.600' />
						<Text ms='0px' fontSize='sm' color='secondaryGray.600'>
							Back to Simmmple
						</Text>
					</Flex>
				</NavLink> */}
				{children}
				<Box
					display={{ base: 'none', md: 'block' }}
					h='100%'
					minH='100vh'
					w={{ lg: '50vw', '2xl': '44vw' }}
					position='absolute'
					right='0px'>
					<Flex
						justifyContent="center" alignItems="center"
						// bg={`url(${illustrationBackground})`}
						justify='center'
						align='end'
						w='100%'
						h='100%'
						bgSize='cover'
						bgPosition='50%'
						position='absolute'
						borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
					>
						<Box
							style={{ background: 'linear-gradient(65deg, #0D53E4,#6E43A4,#F25D2D)' }}
							width="100%"
							height="100%"
							borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
						/>
						<Heading
							color="white"
							fontWeight="semibold" // Adjust this value for boldness (e.g., 'normal', 'bold', 'extrabold')
							position="absolute" // To overlay the text on the image
							textAlign="center"
							fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}
							width="100%" // Ensure the text is centered in the Flex container
							// height="10%"
							borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}

						>
							aHealthy Company
						</Heading>

					</Flex>
				</Box>
				<Footer />
			</Flex>
			{/* <FixedPlugin /> */}
		</Flex>
	);
}
// PROPS

AuthIllustration.propTypes = {
	illustrationBackground: PropTypes.string,
	image: PropTypes.any
};

export default AuthIllustration;
