import { Icon } from '@chakra-ui/react';
// Assets
// Custom components
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { useEffect } from 'react';
import { HiCheck } from "react-icons/hi";
import { useFriendRequestsCount } from './useFriendRequestsCount';

type Props = { brandColor: string; boxBg: string; }
export default function FriendRequestsCount({ brandColor, boxBg }: Props) {
    const { data, refetch } = useFriendRequestsCount();

    useEffect(() => {
        refetch();
    }, []);

    return (
        <MiniStatistics
            startContent={
                <IconBox
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={<Icon w='40px' h='40px' as={HiCheck} color={brandColor} />}
                />
            }
            name='Connections'
            value={data ? data.count : '--'}
            growth={-1}
        />
    )
}
