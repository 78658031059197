import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import moment from 'moment';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable
} from '@tanstack/react-table';

// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { useEffect } from 'react';
import { ClassRow, useClasses } from './useClasses';


const columnHelper = createColumnHelper<ClassRow>();

// const columns = columnsDataCheck;
export default function ClassesTable(props: { tableData: any }) {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const { data, refetch } = useClasses();

    useEffect(() => {
        refetch();
    }, []);
    useEffect(() => { console.log(data); }, [data])
    const columns = [
        columnHelper.accessor('title', {
            id: 'name',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Title
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('participants', {
            id: 'participants',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Participants
                </Text>
            ),
            cell: (info) => {
                console.log(info.getValue());
                return (
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                )
            }
        }),
        columnHelper.accessor('instructor', {
            id: 'instructor',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Instructor
                </Text>
            ),
            cell: (info) => {
                console.log(info.getValue());
                return (
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                )
            }
        }),
        // columnHelper.accessor('startTime', {
        //     id: 'Start',
        //     header: () => (
        //         <Text
        //             justifyContent='space-between'
        //             align='center'
        //             fontSize={{ sm: '10px', lg: '12px' }}
        //             color='gray.400'>
        //             Time
        //         </Text>
        //     ),
        //     cell: (info) => {
        //         const date = new Date(info.getValue());
        //         return (
        //             <Text color={textColor} fontSize='sm' fontWeight='700'>
        //                 {/* {`${date.toLocaleString('en-US')}`} */}
        //                 {moment(date).format('MM Do YYYY, h:mm:ss a')} {/* Adjust format as needed */}

        //             </Text>
        //         )
        //     }
        // })
        columnHelper.accessor('startTime', {
            id: 'dayOfWeek',
            header: () => <Text fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>Day</Text>,
            cell: info => {
                const date = new Date(info.getValue());
                return <Text color={textColor} fontSize='sm' fontWeight='700'>{moment(date).format('ddd')}</Text>;
            }
        }),
        columnHelper.accessor('startTime', {
            id: 'time',
            header: () => <Text fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>Time</Text>,
            cell: info => {
                const date = new Date(info.getValue());
                return <Text color={textColor} fontSize='sm' fontWeight='700'>{moment(date).format('h:mm a')}</Text>;
            }
        }),
        columnHelper.accessor('startTime', {
            id: 'date',
            header: () => <Text fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>Date</Text>,
            cell: info => {
                const date = new Date(info.getValue());
                return <Text color={textColor} fontSize='sm' fontWeight='700'>{moment(date).format('MM/DD/YY')}</Text>;
            }
        }),
    ];
    const table = useReactTable({
        data: data || [],
        columns,
        state: {
            sorting
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true
    });

    const [visibleRowCount, setVisibleRowCount] = React.useState(10);

    const showMoreRows = () => {
        setVisibleRowCount((prevCount) => prevCount + 10); // Show 10 more rows
    };
    return (
        <Card
        flexDirection='column' w='100%' px='0px'
        // overflowX={{ sm: 'scroll', lg: 'hidden' }}
        >
                <Text  color={textColor } px='30px' py='10px'  fontSize='2xl' mb="4px" fontWeight='700' lineHeight='100%'>
                    Classes
                </Text>
            <Flex flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }} >
            <Flex align='center' w='100%' px='15px' >

                {/* <Menu /> */}
            </Flex>
            <Box>
                <Table variant='simple' color='gray.500' mb='24px' mt="12px">
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            pe='10px'
                                            borderColor={borderColor}
                                            cursor='pointer'
                                            onClick={header.column.getToggleSortingHandler()}>
                                            <Flex
                                                justifyContent='space-between'
                                                align='center'
                                                fontSize={{ sm: '10px', lg: '12px' }}
                                                color='gray.400'>
                                                {flexRender(header.column.columnDef.header, header.getContext())}{{
                                                    asc: '',
                                                    desc: '',
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </Flex>
                                        </Th>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                    {table.getRowModel().rows.slice(0, visibleRowCount).map((row) => {
                            return (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <Td
                                                key={cell.id}
                                                fontSize={{ sm: '14px' }}
                                                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                                borderColor='transparent'>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>

            </Box>


            </Flex>

            <Flex width="100%" justifyContent={"center"}>

                        {visibleRowCount < table.getRowModel().rows.length && (
                                <Button bg="#F5F7FF" onClick={showMoreRows}>Show More</Button>
                            )}
            </Flex>
        </Card>
    );
}