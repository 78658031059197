import { useQuery } from 'react-query';
import { useApiClientContext } from '../../services/api/ApiClientProvider';
import { useSelectedLocation } from '../LocationPicker/useSelectedLocation';
import { useGetSelectedTimeRange } from '../_shared/hooks/useSelectedTimeRange';
export type ActivityCountDto = {
    count: number;
    participationCount: number;
};


export const useActivityCount = () => {
    const { startTime, endTime } = useGetSelectedTimeRange();
    const location = useSelectedLocation();
    const { apiClient } = useApiClientContext();
    return useQuery({
        queryKey: ['activityCount', location?.id, { startTime: startTime?.toISOString(), endTime: endTime?.toISOString() }], queryFn: () => apiClient.getActivityCount({ locationId: location?.id, startTime, endTime })
    });
};