import * as d3Random from 'd3-random';

export function generateRandomTimeFollowingNormalDistribution(startTime: Date, endTime: Date): Date {
    const mean1 = startTime.getTime() + (endTime.getTime() - startTime.getTime()) / 3;
    const mean2 = startTime.getTime() + (endTime.getTime() - startTime.getTime()) * 2 / 3;

    // Standard deviation determines the spread of the distribution
    const stdDeviation = (endTime.getTime() - startTime.getTime()) / 6;

    // Generate random numbers from normal distributions using d3-random
    const randomTime1 = d3Random.randomNormal(mean1, stdDeviation)();
    const randomTime2 = d3Random.randomNormal(mean2, stdDeviation)();

    const signUpTime1 = new Date(Math.min(Math.max(randomTime1, startTime.getTime()), endTime.getTime()));
    const signUpTime2 = new Date(Math.min(Math.max(randomTime2, startTime.getTime()), endTime.getTime()));

    // Choose one of the two generated times randomly
    const randomTime = Math.random() < 0.5 ? signUpTime1 : signUpTime2;

    return randomTime;
}